
import { handleError } from "@/core/helpers/errorHandler";
import FamiliasModule from "@/store/modules/FamiliasModule";
import axios from "axios";
import { computed, defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "kt-profile",
  components: {},

  setup() {
    const router = useRouter();

    const store = useStore();
    const moduleFamilias = getModule(FamiliasModule, store);

    onBeforeMount(() => {
      try {
        moduleFamilias.fetch();
      } catch (error) {
        handleError(error);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.push("/home");
        }
      }
    });
    const familia = computed(() => moduleFamilias.familia);

    return {
      familia,
    };
  },
});
