import axios from "axios";
import { ElMessage } from "element-plus";
import i18n from "../plugins/i18n";

const { t } = i18n.global;

export function handleError(error: any) {
  console.error(error);

  if (axios.isAxiosError(error) && error.response?.status) {
    const statusCode = error.response.status;
    const errorCode = error.response.data?.error?.errorCode;

    const text = errorCode
      ? t(`error.${statusCode}.${errorCode}`, t(`error.${statusCode}.default`))
      : t(`error.${statusCode}.default`);
    return ElMessage.error(text);
  } else if (axios.isAxiosError(error) && error.code === "ERR_NETWORK") {
    return ElMessage.error(t("error.network"));
  } else if (error.error?.statusCode === 413) {
    return ElMessage.error(t("error.payloadTooLarge"));
  }

  return ElMessage.error(t("error.default"));
}
